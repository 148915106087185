import routerOptions0 from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.5_@upstash+redis@1.34.4_@vercel+blob@0.27_gql5eutxmrbrq325syrvbto7uu/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/vercel/path0/node_modules/.pnpm/@nuxt+ui-pro@1.7.0_axios@1.7.9_change-case@5.4.4_magicast@0.3.5_typescript@5.7.3_vite@6.1.1_@_6x3wvgqwf43c5waem5hado2nvm/node_modules/@nuxt/ui-pro/modules/pro/runtime/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export const hashMode = routerOptions0.hashMode ?? false
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}