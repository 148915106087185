
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as balanceFollowUp3zbRWTgnKNMeta } from "/vercel/path0/pages/balanceFollowUp.vue?macro=true";
import { default as bulletinqZf4MM99VQMeta } from "/vercel/path0/pages/bulletin.vue?macro=true";
import { default as caseFilingMmCCk1jmolMeta } from "/vercel/path0/pages/caseFiling.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as leaves2nDZeJEK0kMeta } from "/vercel/path0/pages/leaves.vue?macro=true";
import { default as maintenanceLjk4bqrWMWMeta } from "/vercel/path0/pages/maintenance.vue?macro=true";
import { default as missivezTpbAitFl7Meta } from "/vercel/path0/pages/missive.vue?macro=true";
import { default as searchBIFC2dE21WMeta } from "/vercel/path0/pages/search.vue?macro=true";
import { default as ticketYzzGDJFtCrMeta } from "/vercel/path0/pages/ticket.vue?macro=true";
import { default as timesheetsPLxSvFbLH8Meta } from "/vercel/path0/pages/timesheets.vue?macro=true";
import { default as translatorrhZbooBanqMeta } from "/vercel/path0/pages/translator.vue?macro=true";
export default [
  {
    name: "balanceFollowUp",
    path: "/balanceFollowUp",
    meta: balanceFollowUp3zbRWTgnKNMeta || {},
    component: () => import("/vercel/path0/pages/balanceFollowUp.vue")
  },
  {
    name: "bulletin",
    path: "/bulletin",
    meta: bulletinqZf4MM99VQMeta || {},
    component: () => import("/vercel/path0/pages/bulletin.vue")
  },
  {
    name: "caseFiling",
    path: "/caseFiling",
    meta: caseFilingMmCCk1jmolMeta || {},
    component: () => import("/vercel/path0/pages/caseFiling.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexe9Brt5DfdhMeta || {},
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "leaves",
    path: "/leaves",
    meta: leaves2nDZeJEK0kMeta || {},
    component: () => import("/vercel/path0/pages/leaves.vue")
  },
  {
    name: "maintenance",
    path: "/maintenance",
    component: () => import("/vercel/path0/pages/maintenance.vue")
  },
  {
    name: "missive",
    path: "/missive",
    meta: missivezTpbAitFl7Meta || {},
    component: () => import("/vercel/path0/pages/missive.vue")
  },
  {
    name: "search",
    path: "/search",
    meta: searchBIFC2dE21WMeta || {},
    component: () => import("/vercel/path0/pages/search.vue")
  },
  {
    name: "ticket",
    path: "/ticket",
    meta: ticketYzzGDJFtCrMeta || {},
    component: () => import("/vercel/path0/pages/ticket.vue")
  },
  {
    name: "timesheets",
    path: "/timesheets",
    meta: timesheetsPLxSvFbLH8Meta || {},
    component: () => import("/vercel/path0/pages/timesheets.vue")
  },
  {
    name: "translator",
    path: "/translator",
    meta: translatorrhZbooBanqMeta || {},
    component: () => import("/vercel/path0/pages/translator.vue")
  }
]