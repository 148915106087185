import payload_plugin_o5Dmrs0OlI from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3__/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_t1uWmwnuug from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.5_@upstash+redis@1.34.4_@vercel+blob@0.27_gql5eutxmrbrq325syrvbto7uu/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_TBhpbn9TnB from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.5_@upstash+redis@1.34.4_@vercel+blob@0.27_gql5eutxmrbrq325syrvbto7uu/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_szg8gM76eM from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.5_@upstash+redis@1.34.4_@vercel+blob@0.27_gql5eutxmrbrq325syrvbto7uu/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_kzU7A6dwFZ from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.5_@upstash+redis@1.34.4_@vercel+blob@0.27_gql5eutxmrbrq325syrvbto7uu/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_2pvun2R3BX from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.5_@upstash+redis@1.34.4_@vercel+blob@0.27_gql5eutxmrbrq325syrvbto7uu/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_KraBysUvE5 from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.5_@upstash+redis@1.34.4_@vercel+blob@0.27_gql5eutxmrbrq325syrvbto7uu/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_YChkftyr6Y from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.5_@upstash+redis@1.34.4_@vercel+blob@0.27_gql5eutxmrbrq325syrvbto7uu/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_oZ9OcWupYR from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3__/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_O0vWsuLRjB from "/vercel/path0/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.5_@upstash+redis@1.34.4_@vercel+blob@0.27_gql5eutxmrbrq325syrvbto7uu/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import slideovers_X7whXyrIWR from "/vercel/path0/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_oqljskDzoR from "/vercel/path0/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_ETtiVmZNmO from "/vercel/path0/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_OVoKJro5pc from "/vercel/path0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_WLsn00x1qh from "/vercel/path0/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import formkitPlugin_pZqjah0RUG from "/vercel/path0/.nuxt/formkitPlugin.mjs";
import scrollbars_client_1eaSMRWBy4 from "/vercel/path0/node_modules/.pnpm/@nuxt+ui-pro@1.7.0_axios@1.7.9_change-case@5.4.4_magicast@0.3.5_typescript@5.7.3_vite@6.1.1_@_6x3wvgqwf43c5waem5hado2nvm/node_modules/@nuxt/ui-pro/modules/pro/runtime/plugins/scrollbars.client.ts";
import presets_B8Awlau7ke from "/vercel/path0/node_modules/.pnpm/@nuxt+ui-pro@1.7.0_axios@1.7.9_change-case@5.4.4_magicast@0.3.5_typescript@5.7.3_vite@6.1.1_@_6x3wvgqwf43c5waem5hado2nvm/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import variables_X04AIm2TLo from "/vercel/path0/node_modules/.pnpm/@nuxt+ui-pro@1.7.0_axios@1.7.9_change-case@5.4.4_magicast@0.3.5_typescript@5.7.3_vite@6.1.1_@_6x3wvgqwf43c5waem5hado2nvm/node_modules/@nuxt/ui-pro/plugins/variables.ts";
import autoAnimate_client_dEqVGS6XIw from "/vercel/path0/plugins/autoAnimate.client.ts";
import posthog_client_fUucxKWhZE from "/vercel/path0/plugins/posthog.client.ts";
import sentry_client_shVUlIjFLk from "/vercel/path0/plugins/sentry.client.ts";
export default [
  payload_plugin_o5Dmrs0OlI,
  revive_payload_client_t1uWmwnuug,
  unhead_TBhpbn9TnB,
  router_szg8gM76eM,
  payload_client_kzU7A6dwFZ,
  navigation_repaint_client_2pvun2R3BX,
  check_outdated_build_client_KraBysUvE5,
  chunk_reload_client_YChkftyr6Y,
  plugin_vue3_oZ9OcWupYR,
  components_plugin_KR1HBZs4kY,
  prefetch_client_O0vWsuLRjB,
  slideovers_X7whXyrIWR,
  modals_oqljskDzoR,
  colors_ETtiVmZNmO,
  plugin_client_OVoKJro5pc,
  plugin_WLsn00x1qh,
  formkitPlugin_pZqjah0RUG,
  scrollbars_client_1eaSMRWBy4,
  presets_B8Awlau7ke,
  variables_X04AIm2TLo,
  autoAnimate_client_dEqVGS6XIw,
  posthog_client_fUucxKWhZE,
  sentry_client_shVUlIjFLk
]